<template>
  <div class="hero is-fullheight hero-background">
    <div class="nav">
      <div class="nickname">
        <h1 style="font-weight: 700; font-size: 25px;">rkubapl</h1>
      </div>
      <div class="links">
        <ul style="list-style: none;padding: 0;">
          <a href="#about-me"><li class="nav-item">{{ $t('navbar.aboutMe') }}</li></a>
          <a href="#portfolio"><li class="nav-item" href="#portfolio">{{ $t('navbar.portfolio') }}</li></a>
          <a href="#contact"><li class="nav-item" href="#contact">{{ $t('navbar.contact') }}</li></a>
        </ul>
      </div>
      <div class="right">
        <div class="icons">
<!--          <div style="display: inline-block;">-->
            <a href="https://twitter.com/rkubapl" target="_blank"><font-awesome-icon icon="fa-brands fa-twitter" class="icon is-clickable"></font-awesome-icon></a>
            <a href="https://facebook.com/rkubapl" target="_blank"><font-awesome-icon icon="fa-brands fa-facebook" class="icon is-clickable"></font-awesome-icon></a>
            <a href="https://discord.rkuba.pl" target="_blank"><font-awesome-icon icon="fa-brands fa-discord" class="icon is-clickable"></font-awesome-icon></a>
            <a href="https://youtube.com/channel/UCdupJ-JLCWGcvPU2Ek3RprQ/" target="_blank"><font-awesome-icon icon="fa-brands fa-youtube" class="icon is-clickable"></font-awesome-icon></a>
            <a href="https://tiktok.com/@rkubapl" target="_blank"><font-awesome-icon icon="fa-brands fa-tiktok" class="icon is-clickable"></font-awesome-icon></a>
            <a href="https://instagram.com/rkubapl" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" class="icon is-clickable"></font-awesome-icon></a>
            <a href="https://github.com/rkubapl" target="_blank"><font-awesome-icon icon="fa-brands fa-github" class="icon is-clickable"></font-awesome-icon></a>
<!--          </div>-->
        </div>
        <div class="langs">
          <img
              src="/languages/pl.png"
              alt="triangle with all three sides equal"
              :class="'lang' + ($i18n.locale === 'pl' ? ' active' : '')" @click="$i18n.locale = 'pl'" />
          <img
              src="/languages/gb.png"
              alt="triangle with all three sides equal"
              :class="'lang' + ($i18n.locale === 'en' ? ' active' : '')" @click="$i18n.locale = 'en'" />
        </div>
      </div>
<!--      -->
<!--      <div class="newline2"></div>-->
    </div>
      <div class="hero-body">
        <div class="container">
          <div class="hero-me">
            <span class="hero-hi">
              <span class="title is-2 dm-sans has-text-white" style="display: block; margin-bottom: 0">
                <span class="medium">{{ $t('hero.title') }} </span>
                <span class="bold">rkubapl</span>
              </span>
              <span class="subtitle is-4 regular has-text-white">{{ $t('hero.description') }}</span>
            </span>
            <div class="hero-av">
              <img src="kaczucha.png" class="avatar">
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      language: 'en'
    }
  }
}
</script>

<style scoped>

</style>
