import Vue from 'vue'
import VueRouter from 'vue-router'
// import Trial from '../views/Trial.vue'
import Home from "@/views/Home";
import Test from "@/views/Test";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  },
  {path: "/twitter", beforeEnter() {location.href = "https://twitter.com/rkubapl"}},
  {path: "/instagram", beforeEnter() {location.href = "https://instagram.com/rkubapl"}},
  {path: "/discord", beforeEnter() {location.href = "https://discord.com/invite/3KFuZ5t"}},
  {path: "/youtube", beforeEnter() {location.href = "https://www.youtube.com/channel/UCdupJ-JLCWGcvPU2Ek3RprQ"}},
  {path: "/facebook", beforeEnter() {location.href = "https://facebook.com/rkubapl"}},
  {path: "/twitch", beforeEnter() {location.href = "https://twitch.tv/rkubapl"}},
  {path: "/tiktok", beforeEnter() {location.href = "https://tiktok.com/@rkubapl"}},
  {path: "/github", beforeEnter() {location.href = "https://github.com/rkubapl"}},
  //404
  {path: "*", redirect: "/"},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
