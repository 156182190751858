<template>
  <div class="ytCard" @click="open" data-aos="flip-up">
    <font-awesome-icon icon="fa-brands fa-youtube" class="youtubeIcon"></font-awesome-icon>
    <lazy-load-thumbnail :source="thumbnail"></lazy-load-thumbnail>
    <div class="ytInfo">
      <img src="/kaczucha.png" class="avatar">
      <div class="ytText">
        <span class="ytTitle" v-html="title"></span>
        <br>
        <span class="date">{{ dateToString(date) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {dateToString} from "@/date";
import LazyLoadThumbnail from "@/components/LazyLoadThumbnail";

export default {
  name: "YoutubeCard",
  components: {LazyLoadThumbnail },
  props: {
    thumbnail: String,
    title: String,
    date: Number,
    id: String
  }, methods: {
    dateToString,
    open() {
      window.open("https://youtube.com/watch?v=" + this.id)
    }
  }
}
</script>

<style scoped>
.avatar {
  width: 48px;
  height: 48px;
}

.ytCard {
  position: relative;
  border-radius: 16px;
  background-color: #fff;
  transition: all .5s ease;
  cursor: pointer;
}

.ytCard:hover {
  transform: translate3D(0,-1px,0) scale(1.03);
}

.date {
  font-size: 16px;
  font-family: 'Roboto','Arial',sans-serif;
  font-weight: 300;
  line-height: 22px;
  color: #606060;
}

.ytTitle {
  font-size: 18px;
  font-family: 'Roboto','Arial',sans-serif;
  font-weight: 500;
  line-height: 22px;
  color: #030303;
}

.ytInfo {
  padding: 4px 16px 16px;
  display: flex;
  align-items: center
}


.ytText {
  text-align: left;
  padding-left: 10px
}

.youtubeIcon {
  position: absolute;
  bottom: 15px;
  right: 15px;

  font-size: 2rem;
  color: #ff0000;
}
</style>
