import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { ConfigProgrammatic  } from 'buefy'
import { Input, Field } from "buefy";

import VueI18n from 'vue-i18n'

import AOS from 'aos'
import 'aos/dist/aos.css'

import { library } from '@fortawesome/fontawesome-svg-core'

import { faTiktok, faInstagram, faFacebook, faTwitter, faYoutube, faDiscord, faGithub, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons'

import { faCode, faCompassDrafting, faPaperPlane, faEnvelope, faEye } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faTiktok, faInstagram, faFacebook, faTwitter, faYoutube, faDiscord, faGithub, faCode, faCompassDrafting, faPaperPlane, faFacebookMessenger, faEnvelope, faEye)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueI18n)

Vue.use(Input)
Vue.use(Field)

Vue.directive("lazyload", LazyLoadDirective)

ConfigProgrammatic.setOptions({
  defaultIconPack: 'fas',
  // ...
})


import './assets/styles.css'
import './assets/bulma.scss'

Vue.config.productionTip = false

function loadLocaleMessages() {
  const locales = require.context(
      "../locales",
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const messages = loadLocaleMessages();

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: messages
})

import { extend } from 'vee-validate';
import { required, email, min } from 'vee-validate/dist/rules';
import LazyLoadDirective from "@/directives/LazyLoadDirective";

extend('required', {
  ...required,
  // the values param is the placeholders values
  message: (_, values) => i18n.t('validations.messages.required', values)
});

extend('email', {
  ...email,
  // the values param is the placeholders values
  message: (_, values) => i18n.t('validations.messages.email', values)
});

extend('min', {
  ...min,
  // the values param is the placeholders values
  message: (_, values) => i18n.t('validations.messages.length', values)
});

new Vue({
  router,
  mounted() {
    AOS.init({once: true})
  },
  i18n,
  render: h => h(App)
}).$mount('#app')
