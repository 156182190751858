<template>
  <div class="twitterCard" @click="open" data-aos="flip-up">
    <font-awesome-icon icon="fa-brands fa-twitter" class="twitterIcon"></font-awesome-icon>
      <div class="top" style="margin-bottom: 4px;">
        <img src="/kaczucha.png" class="avatar">
        <div class="text">
          <span class="nickname">rkubapl</span>
          <br>
          <span class="mention">@rkubapl</span>
        </div>
      </div>
    <span class="content">{{ content }}</span>
    <br>
    <span class="date">{{ dateToString(date) }}</span>
    <div class="controlContainer">
      <div class="twitterControl">
        <img src="/twitter-icons/comment.svg">
        <span>{{ commentCount }}</span>
      </div>
      <div class="twitterControl">
        <img src="/twitter-icons/retweet.svg" class="retweet">
        <span>{{ retweetCount }}</span>
      </div>
      <div class="twitterControl">
        <img src="/twitter-icons/like.svg">
        <span>{{ likeCount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {dateToString} from "@/date";

export default {
  name: "TwitterCard",
  props: {
    commentCount: Number,
    retweetCount: Number,
    likeCount: Number,
    content: String,
    username: String,
    mention: String,
    date: Number,
    id: String
  }, methods: {
    dateToString,
    open() {
      window.open("https://twitter.com/rkubapl/status/" + this.id)
    }
  }
}
</script>

<style scoped lang="scss">
$x: 32px;

.twitterCard {
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;
  transition: all .5s ease;

  position: relative;
  cursor: pointer;
}

.twitterCard:hover {
  transform: translate3D(0,-1px,0) scale(1.03);
}

.avatar {
  width: 42px;
  height: 42px;
}

.top {
  display: flex;
  align-items: center;
}
.text {
  margin-left: 8px;
  display: block;
  font-size: 0;
}

.roman {
  letter-spacing: 0;
  font-family: Poppins,sans-serif;
}

.boldd {
  font-family: Poppins,sans-serif;
}

.twitterIcon {
  position: absolute;
  top: 15px;
  right: 15px;

  font-size: 2rem;
  color: #1DA1F2;
}

.twitterControl {
  display: flex;
  align-items: center;

  gap: 5px;
}

.twitterControl > img {
  width: 1.125rem;
}
.retweet {
  width: 1.4rem !important;
}


.twitterControl > span {
  color: #657786;
  font-size: 1.05rem;
}

.controlContainer {
  display: flex;
  align-items: center;
  gap: 24px;

  margin-top: 6px;
}

.nickname {
  font-size: 17px;
  color: #000;
  line-height: 20px;
  font-weight: 700;
  font-family: Poppins,sans-serif;
}

.mention {
  line-height: 18px;
  font-size: 15px;
  color: #657786;
  font-weight: 400;
  font-family: Poppins,sans-serif;
}

.content {
  font-weight: 400;
  color: #0f1419;
  font-size: 23px;
  font-family: Poppins,sans-serif;
}

.date {
  font-weight: 300;
  color: #657786;
  font-size: 16px;
  font-family: Poppins,sans-serif;
}
</style>
