<template>
  <div id="app">
    <div v-if="!loaded" class="loading"></div>
    <router-view :class="(loaded ? 'anim' : '')"/>
  </div>
</template>

<style lang="scss">

</style>

<script>
export default {
  name: "App",
  data() {
    return {
      loaded: false
    }
  },
  mounted() {
    document.onreadystatechange = () => {
      if(document.readyState === "complete") {
        // window.scrollTo(0, 0);
        this.loaded = true;
      }
    }
  }
}
</script>
