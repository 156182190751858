<template>
  <TwitterCard></TwitterCard>
</template>

<script>
import TwitterCard from "@/components/social-media/TwitterCard";
export default {
  name: "Test",
  components: {TwitterCard}
}
</script>

<style scoped>

</style>
