<template>
  <figure v-lazyload>
    <img
        class="thumbnail"
        v-lazyload
        :data-url="source"
        alt="random image"
    >
  </figure>
</template>

<script>
export default {
  name: "LazyLoadThumbnail",
  props: {
    source: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.thumbnail {
  width: 100%;
  border-radius: 16px 16px 0 0;
  height: 225px;
  object-fit: cover;
}

@media only screen and (max-width: 900px) {
  .thumbnail {
    height: 175px;
  }
}
</style>
